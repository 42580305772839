import {videoHTML} from '../../../../../js/templates/video-markup.js'

document.addEventListener("DOMContentLoaded", function() {

    if (!window.BATO) window.BATO = {};

    const loadSwiper = (slideNo) => {

        const allCarousels = document.querySelectorAll(".swiper-container");
        let carouselArray = [];

        if(allCarousels.length > 0 && !allCarousels[0].classList.contains("carousel-authoring-mode")) {

            allCarousels.forEach(function(carousel,index) {

                if(!carousel.parentElement.parentElement.classList.contains("media-gallery")) {

                    let allSlides = carousel.querySelectorAll(".swiper-slide"),
                        allSlidesLength = allSlides.length,
                        carouselBtn = carousel.querySelectorAll(".swiper-button-white"),
                        carouselBtnNext = carousel.querySelector('.swiper-button-next'),
                        carouselBtnPrev = carousel.querySelector('.swiper-button-prev'),
                        autoPlay = carousel.getAttribute("data-cmp-autoplay"),
                        scrollBar = carousel.getAttribute("data-cmp-scrollbar"),
                        arrows = carousel.getAttribute("data-cmp-arrows"),
                        widthAuto = carousel.getAttribute("data-cmp-widthauto"),
                        slidesPerView = carousel.getAttribute("data-cmp-cards"),
                        slidesPerSwipe = carousel.getAttribute("data-cmp-group"),
                        thumbGallery = carousel.getAttribute("data-cmp-thumbs"),
                        effect = carousel.getAttribute("data-cmp-effect"),
                        galleryThumbs = carousel.nextElementSibling,
                        gallerySwiper = "",
                        initialSlide = carousel.getAttribute("data-cmp-initialslide"),
                        autoHeight = carousel.getAttribute("data-cmp-autoheight") === 'true' ? true : false;

                    if (allSlidesLength<slidesPerView) {
                        slidesPerView = Math.floor(slidesPerView);
                    }

                    if (carouselBtn.length == 0) {
                        var carouselPar = carousel.parentElement;

                        carouselBtnNext = carouselPar.querySelector('.swiper-button-next');
                        carouselBtnPrev = carouselPar.querySelector('.swiper-button-prev');
                    }

                    const swiperOptions = {
                        autoHeight: autoHeight,
                        direction: 'horizontal',
                        loop: false,
                        loopedSlides: allSlidesLength, // if thumb gallery, looped slides need to be the same
                        navigation: {
                            nextEl: carouselBtnNext,
                            prevEl: carouselBtnPrev
                        },
                        on: {
                            transitionEnd: function(swipe){

                                if(arrows == "true") {
                                    
                                    //this needs to be revisited
                                    let correctedIndex = parseInt(swipe.activeIndex) + parseInt(slidesPerView);
                                    let numViews = swipe.snapGrid.length;
                                    let currView = swipe.snapIndex+1;

                                    //console.log('CAROUSEL (slides:' +slidesPerView+ ') --- activeIndex: ' + swipe.activeIndex + '  |  correctedIndex:' + correctedIndex + '  |  currView:' + currView + '  |  numViews:' + numViews, swipe);

                                    if (numViews<=1) {
                                        //console.log('CAROUSEL: hide arrows');
                                        // .swiper-button-disabled
                                        carouselBtnPrev?.classList.add('hide');
                                        carouselBtnNext?.classList.add('hide');
                                    } else {
                                        //console.log('CAROUSEL: SHOW arrows');
                                        carouselBtnPrev?.classList.remove('hide');
                                        carouselBtnNext?.classList.remove('hide');
                                    }

                                    if (currView==1) { //was 0
                                        carouselBtnPrev?.classList.remove('active');
                                    } else {
                                        carouselBtnPrev?.classList.add('active');
                                    }

                                    if (currView==numViews) {
                                        carouselBtnNext?.classList.remove('active');
                                    } else {
                                        carouselBtnNext?.classList.add('active');
                                    }
                                    //console.log("some debug, currView, numViews; ",currView, ":", numViews)
                                    /*
                                    if( correctedIndex < allSlidesLength) {
                                        carouselBtnPrev.classList.add('active');
                                        carouselBtnNext.classList.add('active');
                                    }

                                    if( correctedIndex == slidesPerView){
                                        carouselBtnPrev.classList.remove('active');
                                    }

                                    if( correctedIndex == allSlidesLength) {
                                        carouselBtnPrev.classList.add('active');
                                        carouselBtnNext.classList.remove('active');
                                    }

                                    if( correctedIndex == 1) {
                                        carouselBtnPrev.classList.remove('active');
                                        carouselBtnNext.classList.add('active');
                                    }
                                    */

                                }
                            }
                        },
                         observer: true,
                         observeParents: true,
                         observeSlideChildren: true
                    };

                    if (effect === 'coverflow') {
                        swiperOptions.effect = 'coverflow';
                        swiperOptions.loop = true;
                        swiperOptions.allowTouchMove = false;
                        swiperOptions.centeredSlides = true;
                        swiperOptions.slideToClickedSlide = true;
                        swiperOptions.preventClicksPropagation = false;
                        swiperOptions.preventClicks = false;
                    }

                    carouselArray.push(
                        new Swiper(carousel, swiperOptions)
                    );

                    slideNo = initialSlide ? parseInt(initialSlide) : 0;
                    
                    if(slideNo => 1) {
                        !carouselArray[index] ? index = 1 : '';
                        carouselArray[index].params.initialSlide = slideNo;
                        carouselArray[index].slideTo(slideNo,0,false);
                    }

                    // AutoPlay Settings
                    if(autoPlay != null) {
                       let autoPlayDelay = carousel.getAttribute("data-cmp-delay");
                        carouselArray[index].params.autoplay.delay = parseInt(autoPlayDelay);
                        carouselArray[index].autoplay.start();
                        
                    }

                    // Allow Slides to Fill Gallery Window At-Will, or Set Breakpoints and GroupBys
                    if(widthAuto == "true") {
                        carouselArray[index].params.breakpoints = {};
                        carouselArray[index].params.slidesPerView = 'auto';
                        carouselArray[index].params.slidesPerGroup = 1;
                        carouselArray[index].params.freeMode = true;
                    } else if (slidesPerView == 1) {
                        carouselArray[index].params.slidesPerView = 1;
                        carouselArray[index].params.slidesPerGroup = 1;
                    } else {
                        /// all this is too specific for enterprise code....
                        let carouselBreaks = {};
                        if( allSlidesLength !== 1 ) {
                            if (effect === 'coverflow') {
                                carouselBreaks = {
                                    // when window width is >= 300px
                                    300: {
                                        slidesPerView: 2,
                                        slidesPerGroup: 1
                                    },
                                    // when window width is >= 540px
                                    540: {
                                        slidesPerView: 2,
                                        slidesPerGroup: 1
                                    },
                                    // when window width is >= 640px
                                    640: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 1
                                    },
                                    // when window width is >= 800px
                                    800: {
                                        slidesPerView: 3,
                                        slidesPerGroup: 1
                                    },
                                    // when window width is >= 1024px
                                    1024: {
                                        slidesPerView: (slidesPerView>allSlidesLength)?allSlidesLength:slidesPerView,
                                        slidesPerGroup: (slidesPerSwipe>allSlidesLength)?allSlidesLength:slidesPerSwipe
                                    },
                                    // when window width is >= 1280px
                                    1280: {
                                        slidesPerView: (slidesPerView>allSlidesLength)?allSlidesLength:slidesPerView,
                                        slidesPerGroup: (slidesPerSwipe>allSlidesLength)?allSlidesLength:slidesPerSwipe
                                    }
                                };
                            } else {
                                carouselBreaks = {
                                    // when window width is >= 300px
                                    300: {
                                        slidesPerView: 1.4,
                                        slidesPerGroup: 1
                                    },
                                    // when window width is >= 540px
                                    540: {
                                        slidesPerView: 2.1,
                                        slidesPerGroup: 2
                                    },
                                    // when window width is >= 640px
                                    640: {
                                        slidesPerView: 2.4,
                                        slidesPerGroup: 2
                                    },
                                    // when window width is >= 800px
                                    800: {
                                        slidesPerView: (3.1>allSlidesLength)?allSlidesLength:3.1,
                                        slidesPerGroup: 3
                                    },
                                    // when window width is >= 1024px
                                    1024: {
                                        slidesPerView: (3.4>allSlidesLength)?allSlidesLength:3.4,
                                        slidesPerGroup: 3
                                    },
                                    // when window width is >= 1280px
                                    1280: {
                                        slidesPerView: (slidesPerView>allSlidesLength)?allSlidesLength:slidesPerView,
                                        slidesPerGroup: (slidesPerSwipe>allSlidesLength)?allSlidesLength:slidesPerSwipe
                                    }
                                };
                            }
                        }
                        !carouselArray[index] ? index = 0 : '';
                        carouselArray[index].params.breakpoints = carouselBreaks;
                    }

                    //console.log('allSlidesLength',allSlidesLength);

                    if(allSlidesLength < 3) {
                        carouselArray[index].params.centerInsufficientSlides = true;
                        carouselArray[index].params.centeredSlides = true;
                        carouselArray[index].params.centeredSlidesBounds = true;
                        carouselArray[index].params.slidesPerView = 1;
                        carouselArray[index].params.slidesPerGroup = 1;
                    }

                    // Show Scrollbar
                    if(scrollBar == "true") {

                        let theScrollbar = document.createElement("div");
                        theScrollbar.classList.add("swiper-scrollbar");
                        carousel.appendChild(theScrollbar);
                        carousel.classList.add("swiper-container--scrollbar");

                        carouselArray[index].params.scrollbar.el = ".swiper-scrollbar";
                        carouselArray[index].params.scrollbar.hide = false;
                        carouselArray[index].params.scrollbar.draggable = true;

                        carouselArray[index].scrollbar.init();
                    }

                    // Show Arrow Button if Set to True
                    if(arrows == "true" && allSlidesLength > 1){
                        carouselBtnNext?.classList.add('active');
                        carouselBtnNext?.classList.remove('hide');
                    }


                    // Check for Thumbnail Gallery & Bind it to Main Gallery
                    if(thumbGallery && galleryThumbs.classList.contains("swiper-container-thumbs")) {

                        gallerySwiper = new Swiper(galleryThumbs, {
                            slidesPerView: allSlidesLength,
                            loop: false,
                            freeMode: true,
                            loopedSlides: allSlidesLength, //looped slides should be the same
                            spaceBetween: 10,
                            watchSlidesVisibility: true,
                            watchSlidesProgress: true,
                        });

                        gallerySwiper.params.initialSlide = slideNo;
                        carouselArray[index].thumbs.swiper = gallerySwiper;
                        carouselArray[index].thumbs.swiper.params.initialSlide = slideNo;
                        carouselArray[index].thumbs.init();

                        gallerySwiper.slideTo(slideNo,0,false);

                    }
                }
            });

        }
    }

    loadSwiper();
    window.BATO.loadSwiper = loadSwiper;

});