export function videoHTML(videoId, videoType, videoSource, videoPoster, videoPosterFile) {

    let videoHTML = `
        <div class="cmp-video">
            <div class="cmp-video-wrapper" id="cmp-video-${videoId}" data-videoid="${videoId}" data-videotype="${videoType}" data-videosource="${videoSource}" data-enableposter="${videoPoster}">
                <div class="cmp-video-poster" id="cmp-video-poster-${videoId}">
                    <div class="play-button-overlay">
                        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 311.69 311.69"><path d="M155.84,0A155.85,155.85,0,1,0,311.69,155.84,155.84,155.84,0,0,0,155.84,0Zm0,296.42A140.58,140.58,0,1,1,296.42,155.84,140.58,140.58,0,0,1,155.84,296.42Z"></path><polygon points="218.79 155.84 119.22 94.34 119.22 217.34 218.79 155.84"></polygon></svg>
                    </div>
                    <picture>
                        <source media="(min-width: 1024px)" srcset="${videoPosterFile}" data-query="desktop">
                        <source media="(min-width: 768px)" srcset="${videoPosterFile}" data-query="tablet">
                        <source srcset="${videoPosterFile}" data-query="mobile">
                        <img src="data:image/gif;base64,R0lGODlhAQABAAAAACH5BAEKAAEALAAAAAABAAEAAAICTAEAOw==" alt="Image needs alt description">
                    </picture>
                </div>
            </div>
        </div>`;

    return videoHTML;

}